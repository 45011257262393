<template>
  <div
    class="event-item border-top d-flex flex-row px-0 py-3"
    :class="{
      clickable: bulkMode,
      selected: isSelected(event.id),
      'bg-light': isSelected(event.id)
    }"
    @click="toogleEventItem(event)"
  >
    <div class="d-flex align-items-center pr-3 pl-2" :class="{ 'imp-hide': !bulkMode }">
      <font-awesome-icon icon="check-circle" class="check-icon" />
    </div>
    <div class="text-amount-wrapper d-flex">
      <div class="flex-fill">
        <div>{{ event.text }}</div>
        <div>
          <small>
            <span class="text-muted">{{ event.occured_at | formatDate }}</span>
            <span v-if="event.owner.id !== currentUser.id" class="pl-1">
              <font-awesome-icon
                icon="user"
                class="text-muted fa-xs"
                style="padding-bottom: 1px"
              />
            </span>
          </small>
        </div>
      </div>

      <div class="event-item-right d-flex flex-column align-items-end">
        <div v-if="event.amount" class="d-flex">
          <p class="font-weight-bold p-0 mb-0">
            {{ event.amount.value }}
          </p>
          <p
            class="font-weight-light ml-1 mb-0"
            v-if="event.amount && event.amount.original"
          >
            ({{ event.amount.original.value }})
          </p>
        </div>
        <div v-else>
          <span v-if="event.processing_error" :title="event.processing_error">
            <font-awesome-icon icon="exclamation-triangle" class="text-danger" />
          </span>
        </div>
        <event-category-selector
          :event-owner-id="event.owner.id"
          :events="[event]"
          :selected-category="event.category"
          :noCategoryText="'Без категории'"
          @category-selected-successfully="onCategorySelectedSuccessfully"
        />
      </div>
    </div>

    <div class="d-flex align-items-center pl-3" :class="{ 'pr-2': bulkMode }">
      <b-dropdown
        no-caret
        variant="link"
        right
        ref="event-dots-dropdown"
        :lazy="true"
        class="event-dots-menu"
      >
        <template #button-content>
          <span class="dots mb-1">•••</span>
        </template>
        <template class="p-0">
          <ul class="p-0 m-0">
            <li class="dropdown-item p-0 m-0">
              <span @click.stop="selectEventItem(event)">Выбрать</span>
            </li>
          </ul>
        </template>
      </b-dropdown>
    </div>
  </div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
import EventCategorySelector from './EventCategorySelector.vue'

export default {
  name: 'EventListItem',
  data () {
    return {}
  },
  props: {
    event: Object,
    filterQuery: String,
    selectedEvents: Object
  },
  methods: {
    selectEventItem (event) {
      this.$refs['event-dots-dropdown'].hide(true)
      this.$emit('event-selected', event)
    },
    toogleEventItem (event) {
      if (!this.bulkMode) {
        return
      }
      if (this.isSelected(event.id)) {
        this.$emit('event-deselected', event)
      } else {
        this.$emit('event-selected', event)
      }
    },
    isSelected (eventId) {
      return eventId in this.selectedEvents
    },
    onCategorySelectedSuccessfully (event) {
      this.$emit('category-selected-successfully', event)
    }
  },
  computed: {
    bulkMode () {
      return Object.keys(this.selectedEvents).length > 0
    },
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
      categoriesByOwner: 'events/getCategoriesByOwner'
    })
  },
  components: {
    EventCategorySelector
  }
}
</script>

<style lang="scss">
.event-dots-menu {
  .btn {
    padding: 0 !important;

    &:focus {
      text-decoration: none;
    }
  }

  .dropdown-menu {
    padding: 0;
    min-width: 200px;
  }
  .dots {
    display: block;
    text-align: right;
    cursor: pointer;
    opacity: 0.4;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>

<style lang="scss" scoped>
.event-item.selected {
  background-color: #fcfdfd;
}

.text-amount-wrapper {
  flex-direction: row;
  flex: 1 1 auto;
}

@media (max-width: 575.98px) {
  .text-amount-wrapper {
    flex-direction: column !important;
  }
  .event-item-right {
    align-items: flex-start !important;
  }
}

.check-icon {
  opacity: 0.2;
}

.selected .check-icon {
  color: #bd3586;
  opacity: 1 !important;
}

.imp-hide {
  display: none !important;
}

.dropdown-item {
  list-style: none;
  position: relative;

  span {
    cursor: pointer;
    text-decoration: none;
    display: block;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.75rem;
    color: #000;
    font-weight: 300;

    &:hover {
      text-decoration: none;
      background-color: #e9ecef;
    }
  }
}

.clickable {
  cursor: pointer;
}
</style>
