<template>
  <div class="category-selector">
    <b-dropdown
      no-caret
      variant="link"
      right
      ref="category-selector-dropdown"
      :lazy="true"
    >
      <template #button-content>
        <a
          href="#"
          :class="{
            'text-danger': selectedCategory && selectedCategory.type == 'outcome',
            'text-success': selectedCategory && selectedCategory.type == 'income',
            'text-muted': selectedCategory === null
          }"
          class="category-link"
        >
          {{ selectedCategory === null ? noCategoryText : selectedCategory.title }}
        </a>
      </template>
      <template class="p-0">
        <select-category-tree
          search-placeholder="Категория..."
          :data="categoriesByOwner(eventOwnerId)"
          :selected-category-id="selectedCategory && selectedCategory.id"
          @click-item="setEventCategory($event)"
        />
      </template>
    </b-dropdown>
  </div>
</template>

<script lang="js">
import { mapGetters } from 'vuex'
import SelectCategoryTree from '@/components/common/SelectCategory/SelectCategoryTree.vue'

export default {
  name: 'EventCategorySelector',
  data () {
    return {}
  },
  methods: {
    setEventCategory (category) {
      if (this.selectedCategory === null || this.selectedCategory.id !== category.id) {
        const updates = []
        for (let i = 0; i < this.events.length; ++i) {
          updates.push({
            event: this.events[i],
            oldCategory: this.selectedCategory,
            newCategory: category
          })
        }
        this.$store.dispatch('events/setCategory', updates)
          .then(() => {
            this.$emit('category-selected-successfully')
          })
      }
      this.$refs['category-selector-dropdown'].hide(true)
      this.$emit('category-selected')
    }
  },
  props: {
    eventOwnerId: String,
    events: Array,
    selectedCategory: {
      type: Object,
      required: false
    },
    noCategoryText: String
  },
  computed: {
    ...mapGetters({
      categoriesByOwner: 'events/getCategoriesByOwner'
    })
  },
  components: {
    SelectCategoryTree
  }
}
</script>

<style lang="scss">
.category-selector {
  .btn {
    padding: 0 !important;
    border: none;
  }

  a,
  .btn-link {
    text-decoration: none;
  }

  .btn-link:hover {
    text-decoration: underline;
  }

  .dropdown-menu {
    padding: 0;
    min-width: 200px;
  }
}
</style>
