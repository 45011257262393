<template>
  <li class="spent-tree-node">
    <div
      v-if="!root"
      :style="elStyle"
      class="d-flex"
    >
      <span
        @click="toggle">
        <font-awesome-icon
          :style="{color: hasChildren ? '#eee' : '#fff', width: '16px', height: '16px'}"
          :icon="isOpen ? 'angle-down' : 'angle-right'"
          class="mr-1 tree-arrow"
        />
        {{ item.title }} — {{ item.amount }}
        <small v-if="item.percent" class="text-muted">{{item.percent }}</small>
      </span>
    </div>
    <ul class="spent-tree-wrapper" v-show="isOpen || root">
      <spent-tree-node
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        :level="root ? 0 : level + 1"
        @make-folder="$emit('make-folder', $event)"
      ></spent-tree-node>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'SpentTreeNode',
  props: {
    item: Object,
    root: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    hasChildren () {
      return this.item.children && this.item.children.length
    },
    elStyle () {
      return {
        marginLeft: (this.level * 10) + 'px',
        cursor: this.hasChildren ? 'pointer' : 'auto'
      }
    }
  },
  methods: {
    toggle () {
      if (this.hasChildren) {
        this.isOpen = !this.isOpen
      }
    },
    makeFolder () {
      if (!this.hasChildren) {
        this.$emit('make-folder', this.item)
        this.isOpen = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spent-tree-node {
  list-style: none;
  position: relative;

  &>div {
    transition: display 85ms ease;

    .tree-dots {
      color: #eee;

      &:hover {
        color: #ccc;
      }
    }
  }
}
.spent-tree-wrapper {
  margin: 0;
  padding: 0;
}
</style>
