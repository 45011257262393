<template>
  <li class="select-category-tree-item">
    <div v-if="!isRoot">
      <a
        href="#"
        class="item d-flex justify-content-between align-items-center"
        :class="{
          'item-hovered': hoveredItemId === item.id,
          'not-in-filter': item.filteredByTitle === false,
          'item-selected': selectedItemId === item.id
        }"
        :style="itemStyle"
        @click.prevent="$emit('click-item', item)"
        @mouseover="$emit('item-hovered', item.id)"
        ><span>{{ item.title }}</span>
        <font-awesome-icon
          icon="check"
          class="text-success"
          style="font-size: 0.8rem"
          v-if="selectedItemId === item.id"
        />
      </a>
    </div>
    <div v-if="isRoot && item.children.length === 0">
      <a href="#" class="item not-found text-muted"><span>Категория не найдена</span></a>
    </div>
    <ul class="select-category-tree-wrapper">
      <select-category-tree-item
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        :level="isRoot ? 0 : level + 1"
        :text-filter="textFilter"
        :hovered-item-id="hoveredItemId"
        :selected-item-id="selectedItemId"
        @click-item="$emit('click-item', $event)"
        @item-hovered="$emit('item-hovered', $event)"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: 'SelectCategoryTreeItem',
  props: {
    item: Object,
    isRoot: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 0
    },
    textFilter: {
      type: String,
      default: ''
    },
    selectedItemId: {
      type: String,
      default: null
    },
    hoveredItemId: {
      type: String,
      default: null
    }
  },
  computed: {
    itemStyle() {
      return {
        paddingLeft: 0.75 + this.level * 0.5 + 'rem',
        paddingRight: 1.5 + 'rem',
        fontWeight: !this.item.is_editable ? 'bold' : 'normal'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-hovered {
  text-decoration: none;
  background-color: #e9ecef;
}

.not-in-filter {
  font-size: 0.8rem;
}

.select-category-tree-item {
  list-style: none;
  position: relative;

  .item {
    display: block;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    color: #000;
    font-weight: 300;
  }

  .not-found {
    padding-left: 0.75rem;
    padding-right: 1.5rem;
    font-size: 0.8rem;
    font-weight: normal;
  }
}
</style>
