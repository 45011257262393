<template>
  <div>
    <ul class="spent-tree-wrapper" v-if="data">
      <spent-tree-node
        :item="data"
        :root="true"
        @make-folder="makeFolder"
      ></spent-tree-node>
    </ul>
  </div>
</template>

<script>
import SpentTreeNode from './SpentTreeNode'
import Vue from 'vue'

export default {
  data () {
    return {}
  },
  props: {
    data: Object
  },
  methods: {
    makeFolder (item) {
      Vue.set(item, 'children', [])
    }
  },
  computed: {},
  components: {
    SpentTreeNode
  }
}
</script>

<style lang="scss" scoped>
.spent-tree-wrapper {
  margin: 0;
  padding: 0;
}
</style>
