<template>
  <div class="select-category-tree rounded">
    <input
      type="text"
      class="form-control select-category-searchbox rounded-top"
      ref="searchbox"
      v-model="searchText"
      :placeholder="searchPlaceholder"
      @keydown.down="hoverItem('down')"
      @keydown.up="hoverItem('up')"
      @keyup.enter="selectItem"
    />
    <div class="select-category-tree-items">
      <ul class="select-category-tree-wrapper">
        <select-category-tree-item
          :item="filteredItems"
          :is-root="true"
          :text-filter="searchText"
          :selected-item-id="selectedCategoryId"
          :hovered-item-id="
            hoveredIndex !== null ? categoriesFlatten[hoveredIndex].id : null
          "
          @click-item="$emit('click-item', $event)"
          @item-hovered="itemHovered"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import SelectCategoryTreeItem from './SelectCategoryTreeItem'

export default {
  name: 'SelectCategoryTree',
  props: {
    searchPlaceholder: {
      type: String,
      default: ''
    },
    data: Object,
    selectedCategoryId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      searchText: '',
      hoveredIndex: null
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.searchbox.focus()

      if (this.selectedCategoryId) {
        const idx = this.categoriesFlatten.findIndex(
          (el) => el.id === this.selectedCategoryId
        )
        if (idx >= 0) {
          this.hoveredIndex = idx
        }
      }

      const selectedItem = this.$el.querySelector('.item.item-selected')
      if (selectedItem) {
        selectedItem.scrollIntoView({
          block: 'nearest'
        })
      }
    }, 10)
  },
  computed: {
    categoriesFlatten() {
      const categories = []
      const iterate = (item) => {
        // Добавляем всех кроме корня
        // тех кто попал в фильтрацию благодаря children
        // или если нет фильтра вообще
        if (
          item.id !== null &&
          (item.filteredByTitle || item.filteredByTitle === null)
        ) {
          // удаляю children чтобы лишнее не хранить
          const { children, ...itemCopy } = item
          categories.push(itemCopy)
        }
        if (item.children && item.children.length > 0) {
          item.children.forEach((childItem) => {
            iterate(childItem)
          })
        }
      }
      iterate(this.filteredItems)
      return categories
    },
    filteredItems() {
      function filter(objs, titleToFilter) {
        return objs.reduce((filtered, obj) => {
          const children = obj.children ? filter(obj.children, titleToFilter) : []
          const filteredChildren = children.length > 0 ? children : null
          const filteredByTitle =
            titleToFilter !== ''
              ? obj.title.toLowerCase().includes(titleToFilter.toLowerCase())
              : null

          if (filteredByTitle === null || filteredByTitle || filteredChildren) {
            filtered.push({
              ...obj,
              children: filteredChildren,
              filteredByTitle: filteredByTitle
            })
          }

          return filtered
        }, [])
      }

      return {
        id: null,
        children: filter(this.data.children, this.searchText)
      }
    }
  },
  methods: {
    itemHovered(itemId) {
      const idx = this.categoriesFlatten.findIndex((el) => el.id === itemId)
      if (idx >= 0) {
        this.hoveredIndex = idx
      }
    },
    hoverItem(direction) {
      if (this.categoriesFlatten.length === 0) return

      if (direction === 'down') {
        if (this.hoveredIndex === null) {
          this.hoveredIndex = 0
        } else {
          this.hoveredIndex = (this.hoveredIndex + 1) % this.categoriesFlatten.length
        }
      } else {
        if (this.hoveredIndex === null || this.hoveredIndex - 1 < 0) {
          this.hoveredIndex = this.categoriesFlatten.length - 1
        } else {
          this.hoveredIndex--
        }
      }

      this.$nextTick(() => {
        const activeItem = this.$el.querySelector('.item.item-hovered')
        if (activeItem) {
          activeItem.scrollIntoView({
            block: 'nearest'
          })
        }
      })
    },
    selectItem() {
      if (this.hoveredIndex === null) return
      const item = this.categoriesFlatten[this.hoveredIndex]
      this.$emit('click-item', item)
    }
  },
  watch: {
    searchText(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.hoveredIndex = null
      }
    }
  },
  components: {
    SelectCategoryTreeItem
  }
}
</script>

<style lang="scss">
.select-category-tree {
  background-color: #fff;
}
.select-category-searchbox {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0;
}
.select-category-tree-wrapper {
  margin: 0;
  padding: 0;
}
.select-category-tree-items {
  overflow-y: scroll;
  max-height: 300px;
}
</style>

<style lang="scss" scoped>
.form-control:focus {
  border-color: inherit;
}
</style>
